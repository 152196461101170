import { createParamsApi } from "../../api"
import { fetcher } from "@/utils/fetcher"

export const SET_PROMO_INFO = 'SET_PROMO_INFO'
export const RESET_PROMO_INFO = 'RESET_PROMO_INFO'

export const getPromoInfo = () => async (dispatch, getState) => {
  const data = await fetcher(createParamsApi({ method: 'getPromoInfo', section: 'promo' }))
  setPromo({ data: data.promotions })(dispatch, getState)
}

const setPromo = ({ data }) => (dispatch, getState) => {
  if (!data || (data && Object.keys(data).length <= 0)) dispatch({ type: RESET_PROMO_INFO })
  else dispatch({ type: SET_PROMO_INFO, data })
}

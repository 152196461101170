export const scrollToTop = (duration) => {
  // cancel if already on top
  console.log(document.scrollingElement.scrollTop)
  if (document.scrollingElement.scrollTop === 0) return;

  const totalScrollDistance = document.scrollingElement.scrollTop;
  let scrollY = totalScrollDistance, oldTimestamp = null;

  function step(newTimestamp) {
    if (oldTimestamp !== null) {
      // if duration is 0 scrollY will be -Infinity
      scrollY -= totalScrollDistance * (newTimestamp - oldTimestamp) / duration;
      if (scrollY <= 0) return document.scrollingElement.scrollTop = 0;
      document.scrollingElement.scrollTop = scrollY;
    }
    oldTimestamp = newTimestamp;
    window.requestAnimationFrame(step)
  }

  window.requestAnimationFrame(step)
}

export const scrollToNode = (duration, scrollNode, yOffset = 0) => {
  // cancel if already on top
  try {
    console.log(scrollNode, scrollNode.offsetTop, yOffset, scrollNode.getBoundingClientRect())

    // scrollNode.scrollIntoView({ blog: 'center', behavior: "smooth" })
    let elemPos = scrollNode.getBoundingClientRect().top
    const isNegative = elemPos < 0
    elemPos = (Math.abs(elemPos) -  ((elemPos > 0 ? 1 : -1) * yOffset)) * (isNegative ? -1 : 1)
    console.log(elemPos, ((elemPos > 0 ? -1 : 1) * yOffset), elemPos - ((elemPos > 0 ? 1 : -1) * yOffset))
    scrollTo(document.getElementById('App'), elemPos, .750);
  } catch (err) {
    // scrollNode.scrollIntoView(true);
  }
}

export function scrollTo(element, to, duration, onDone) {
  let start = element.scrollTop,
    //change = to - start,
    change = to,
    startTime = performance.now(),
    val, now, elapsed, t;
  console.log(start, to)

  function animateScroll() {
    now = performance.now();
    elapsed = (now - startTime) / 1000;
    t = (elapsed / duration);

    element.scrollTop = start + change * easeInOutQuad(t);

    if (t < 1)
      window.requestAnimationFrame(animateScroll);
    else
      onDone && onDone();
  };

  animateScroll();
}

function easeInOutQuad(t) { return t < .5 ? 2 * t * t : -1 + (4 - 2 * t) * t }
